import React, {useContext, useEffect} from "react";
import Layout from "../../Layout";
import {PageInfoContext} from "../../contexts/PageInfoContext";
import {Link} from "react-router-dom";

export default function FreePublicInfrastructure(): JSX.Element {
    const { setTitle } = useContext(PageInfoContext);

    useEffect(() => {
        setTitle("Free Public Infrastructure (FPI)");
    }, []);

    return (
        <Layout>
            <>
                <p className={"small mb-5 text-center"}>
                    <Link to={"/guides"}>Guides</Link> / Free Public Infrastructure (FPI)
                </p>
                <header className="hero py-2 pb-4">
                    <h1 className={"m-0"} style={{ fontSize: "2.5rem" }}>
                        Free Public Infrastructure (FPI)
                    </h1>
                </header>
                <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8" style={{ letterSpacing: ".5px "}}>
                        <p>Canto aims to launch core DeFi primitives such as decentralized exchanges, lending markets, and decentralized unit of accounts as public utility protocols, known as Free Public Infrastructure (FPI). The vision of FPI is that core cryptoeconomic primitives should exist as Free Public Infrastructure (FPI).</p>
                        <p>Canto has published 3 FPI primitives so far:</p>
                        <ol>
                            <li>
                                Canto DEX
                            </li>
                            <li>
                                Canto Lending Marketing
                            </li>
                            <li>
                                $NOTE, Canto&apos;s unit of account token
                            </li>
                        </ol>
                        <p>For Canto&apos;s decentralized exchange, the protocol is ungoverned and cannot be upgraded, ensuring no predatory evolution. This means that it will run indefinitely without the possibility of launching a token or implementing additional fees over time.</p>
                        <p>Canto Lending Market is governed by Canto stakers, who are incentivized to foster a healthy DeFi environment. They have no incentive to extract rent at the application layer, which ensures a fair and transparent ecosystem.</p>
                        <p>$NOTE interest is used to fund public goods and is distributed fairly to lenders, with no fees extracted. The algorithm responsible for adjusting this interest rate is designed to change the interest rate in order to promote a less volatile value, which benefits all users.</p>
                        <p>Existing protocols often extract rent from users, whereas Canto&apos;s FPI intends to provide for its community without additional fees or rent-seeking behavior. For Canto&apos;s decentralized exchange, the protocol cannot be upgraded and will remain ungoverned. It will run in perpetuity on Canto without ever being able to launch a token or implement additional fees over time, preventing the possibility of a predatory evolution towards rent-seeking behaviors.</p>
                        <p>The governance of the Canto Lending Market is overseen by individuals who hold Canto tokens, known as stakers. These stakers have a vested interest in the growth and success of the ecosystem, and strive to create an optimal environment for both developers and users of DeFi applications. As a result, there is no motivation for them to impose additional fees or extract rent at the application level.</p>
                        <p>Canto&apos;s unit of account token, $NOTE, is designed to be used in a way that benefits the community. The interest charged on $NOTE is used to fund public goods, and the algorithm that determines the interest rate is focused on promoting stability in the value of $NOTE, rather than maximizing revenue. Additionally, all interest paid by borrowers is distributed to lenders, without any fees being taken by the protocol.</p>
                        <p>Canto believes that by assigning governance powers to the chain, the operations of core DeFi primitives will align with the public interest, resulting in a stable and strong ecosystem that promotes collaboration and fairness in the decentralized finance space.</p>
                        <h4 className={"h5 text-white mt-5"}>
                            Additional Resources
                        </h4>
                        <ol>
                            <li>
                                <a href="https://docs.canto.io/readme/free-public-infrastructure-fpi" target={"_blank"} rel="noreferrer">
                                    Canto Docs: Free Public Infrastructure (FPI)
                                </a>
                            </li>
                            <li>
                                <a href="https://canto.mirror.xyz/STFv6-ZnOrsR6pJ7_JyFPt5JuhBXGQ3_lHNVEZAgUW8" target={"_blank"} rel="noreferrer">
                                    Canto @ Mirror: DeFi Primitives Are Best As Free Public Infrastructure
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </>
        </Layout>
    );
}
