import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Layout from "src/Layout";
import {PageInfoContext} from "../contexts/PageInfoContext";
import {IProject} from "../interfaces/Project";

const encodeCategoryName = (categoryName: string) => encodeURIComponent(categoryName
    .split(" ")
    .join("-")
);

const decodeCategoryName = (categoryName: string) => decodeURIComponent(categoryName
    .split("-")
    .join(" ")
);

export default function Ecosystem(): JSX.Element {
    const [allProjects, setAllProjects] = useState<{ [projectKey: string]: IProject } | null>(null);
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [projects, setProjects] = useState<IProject[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    const { setTitle } = useContext(PageInfoContext);

    const navigate = useNavigate();

    const changeCategory = (category: string | null) => {
        setSelectedCategory(category);
        navigate(null === category ? "/ecosystem" : "/ecosystem?category="+encodeCategoryName(category));
    };

    // Effects
    useEffect(() => {
        setTitle("Ecosystem");
    }, []);

    useEffect(() => {
        if (null !== allProjects && Object.keys(allProjects).length > 0) {
            setProjects(
                Object.keys(allProjects)
                    .filter(projectKey => null === selectedCategory || allProjects[projectKey].categories.includes(decodeCategoryName(selectedCategory)))
                    .map(projectKey => allProjects[projectKey])
                    .sort((a, b) => a.name.localeCompare(b.name))
            );
        }
    }, [selectedCategory, allProjects]);

    useEffect(() => {
        if (null !== allProjects && Object.keys(allProjects).length > 0) {
            const categoryList = Object.values(allProjects)
                .map((project: IProject) => project.categories)
                .flat(1);

            setCategories(
                categoryList
                    .filter((category: string, index) => categoryList.indexOf(category) === index)
                    .sort((a: string, b: string) => a.localeCompare(b))
            );
        }
    }, [allProjects]);

    useEffect(() => {
        if ((searchParams.get("category") || "").length > 0) {
            setSelectedCategory(searchParams.get("category"));
        }

        fetch("https://raw.githubusercontent.com/CantoTools/data/main/src/projects.json")
            .then(response => response.json())
            .then((data: { [projectKey: string]: IProject }) => {
                setAllProjects(data);
            });
    }, []);

    return (
        <Layout width={"wide"}>
            <>
                <header className="hero py-2 pb-4">
                    <h1 className={"m-0"}>
                        🌱 Ecosystem
                    </h1>
                    <p className="ms-3 text-muted lead mt-3">
                        {null === allProjects ? <i>Loading...</i> : Object.keys(allProjects).length+" projects"}
                    </p>
                </header>
                <div className="row">
                    <div className="col-md-3 col-lg-2">
                        <ul className="list-unstyled mb-5">
                            <li className={"mb-3"}>
                                <button
                                    type={"button"}
                                    onClick={() => changeCategory(null)}
                                    className="btn btn-dark w-100 text-start"
                                >
                                    <div className="d-flex w-100 justify-content-between">
                                        <div>
                                            All
                                        </div>
                                        <div>
                                            {selectedCategory ? <span /> : <span key={"all_icon"}><i className={"fas fa-check"} /></span>}
                                        </div>
                                    </div>
                                </button>
                            </li>
                            {categories
                                .map((categoryItem: string) => (
                                    <li key={categoryItem} className={"mb-3"}>
                                        <button
                                            type={"button"}
                                            onClick={() => changeCategory(
                                                selectedCategory === categoryItem ? null : categoryItem
                                            )}
                                            className="btn btn-dark w-100 text-start"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <div>
                                                    {categoryItem}
                                                    <span className="ms-3 text-muted">
                                                        {Object.values(allProjects || []).filter(project => project.categories.includes(categoryItem)).length}
                                                    </span>
                                                </div>
                                                <div>
                                                    {selectedCategory === encodeCategoryName(categoryItem) ? <span key={categoryItem+"_icon"}><i className={"fas fa-check"} /></span> : <span />}
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col-md-9 col-lg-10">
                        {projects.length ? (
                            <div className="projects">
                                <div className="card">
                                    <table className="table">
                                        <tbody>
                                            {projects
                                                .map(project => (
                                                    <tr
                                                        key={project.name}
                                                    >
                                                        <td>
                                                            <div className="d-flex w-100">
                                                                <div>
                                                                    <Link
                                                                        to={"/projects/"+project.id}
                                                                        className={"d-block position-relative"}
                                                                        style={{
                                                                            width: "100px",
                                                                            height: "100px",
                                                                        }}
                                                                    >
                                                                        {project.logo ? (
                                                                            <img
                                                                                src={"https://raw.githubusercontent.com/CantoTools/data/main/src/assets/logos/"+project.logo}
                                                                                alt={project.name}
                                                                                style={{
                                                                                    maxWidth: "100%",
                                                                                    maxHeight: "100%",
                                                                                    position: "absolute",
                                                                                    top: "50%",
                                                                                    left: "50%",
                                                                                    transform: "translate(-50%, -50%)",
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <span style={{
                                                                                display: "inline-block",
                                                                                width: "100px",
                                                                                height: "100px",
                                                                                backgroundColor: "#333",
                                                                            }}/>
                                                                        )}
                                                                    </Link>
                                                                </div>
                                                                <div className={"ps-3"}>
                                                                    <p className="h5 fw-bold m-0">
                                                                        <Link to={"/projects/"+project.id} className={"text-decoration-none text-white"}>
                                                                            {project.name}
                                                                        </Link>
                                                                    </p>
                                                                    <p className={"m-0"}>
                                                                        {project.categories.join(", ")}
                                                                    </p>
                                                                    <p className={"small text-muted mb-1"}>
                                                                        {project.description || <>&nbsp;</>}
                                                                    </p>
                                                                    <ul className="list-inline m-0">
                                                                        {project?.socials?.twitter && (
                                                                            <li className={"list-inline-item"}>
                                                                                <a href={project.socials.twitter} target={"_blank"} className={"small text-decoration-none"} rel="noreferrer">
                                                                                    <i className="fab fa-twitter" /> Twitter
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        {project?.socials?.discord && (
                                                                            <li className={"list-inline-item"}>
                                                                                <a href={project.socials.discord} target={"_blank"} className={"small text-decoration-none"} rel="noreferrer">
                                                                                    <i className="fab fa-discord" /> Discord
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        {project?.website && (
                                                                            <li className={"list-inline-item"}>
                                                                                <a href={project.website} target={"_blank"} className={"text-decoration-none small"} rel="noreferrer">
                                                                                    {project.website.replace("https://", "")}
                                                                                    <small>
                                                                                        <sup>
                                                                                            <span className="fa-solid fa-arrow-up-right-from-square ms-2" />
                                                                                        </sup>
                                                                                    </small>
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <p className={"lead"}>
                                {null === allProjects ? "Loading..." : "No projects matched your criteria."}
                            </p>
                        )}
                    </div>
                </div>
            </>
        </Layout>
    );
}
