import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Layout from "../Layout";
import Moment from "react-moment";
import {PageInfoContext} from "../contexts/PageInfoContext";
import moment from "moment";
import {IEvent} from "../interfaces/Event";
import {IEventCategory} from "../interfaces/EventCategory";

export default function Event(): JSX.Element {
    const { eventId } = useParams<{ eventId: string }>();

    const { setTitle } = useContext(PageInfoContext);

    // States
    const [eventCategories, setEventCategories] = useState<{[eventCategoryId: string]: IEventCategory} | null>(null);
    const [events, setEvents] = useState<IEvent[] | null>(null);
    const [event, setEvent] = useState<IEvent | null>(null);

    useEffect(() => {
        if (null !== events) {
            console.log(events);
            setEvent(eventId && events.filter((event: IEvent) => event.id === eventId) ? events.filter((event: IEvent) => event.id === eventId)[0] : null);
        }
    }, [eventId, events]);

    useEffect(() => {
        setTitle(event?.name ?? null);
    }, [event]);

    useEffect(() => {
        // fetch events
        fetch("https://raw.githubusercontent.com/CantoTools/data/main/src/events.json")
            .then(response => response.json())
            .then((data: { [eventKey: string]: IEvent }) => {
                setEvents(
                    Object.keys(data)
                        .map((eventKey: string): IEvent => ({
                            ...data[eventKey],
                            id: eventKey
                        }))
                );
            });

        // fetch event categories
        fetch("https://raw.githubusercontent.com/CantoTools/data/main/src/event-categories.json")
            .then(response => response.json())
            .then((data: { [eventCategoryId: string]: IEventCategory }) => {
                setEventCategories(data);
            });
    }, []);

    return (
        <Layout width={"wide"}>
            <>
                {event ? (
                    <>
                        <header className="hero py-3 my-3">
                            <h1 className={"m-0"}>
                                {event.name}
                            </h1>
                            <p className="ms-3 lead mt-3">
                                <a
                                    href={event.url}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    className="btn btn-dark btn-lg"
                                >
                                    <i className="fas fa-link" /> View Event Website{" "}
                                    <sup>
                                        <span className="fa-solid fa-arrow-up-right-from-square" />
                                    </sup>
                                </a>
                            </p>
                        </header>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-6 col-lg-4">
                                    <div className="card h-100">
                                        <div className="card-header text-center">
                                            Categories
                                        </div>
                                        <div className="card-body">
                                            {null === eventCategories ? (
                                                <i>Loading...</i>
                                            ) : (
                                                <ul className="list-unstyled text-center m-0 mt-2">
                                                    {event.eventCategoryIds.map(eventCategoryId => <li key={eventCategoryId} className={"mb-3"}>{eventCategories[eventCategoryId]?.name || "Unknown"}</li>)}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="card">
                                        <div className="card-header text-center">
                                            Dates
                                        </div>
                                        <div className="card-body">
                                            <p className="ms-3 lead">
                                                <span className="text-white">Starts at:</span><br />
                                                <Moment date={event.startUTC} format="HH:mm - MMM DD, YYYY" />
                                            </p>
                                            <p className="ms-3 lead mt-3">
                                                <span className="text-white">Ends at:</span><br />
                                                <Moment date={event.endUTC} format="HH:mm - MMM DD, YYYY" />
                                            </p>
                                            <p className="text-center">
                                                <a
                                                    href={"https://calendar.google.com/calendar/render?action=TEMPLATE&dates="+(moment(event.startUTC).format("YYYYMMDDTHHmmss")+"Z")+"%2F"+(moment(event.endUTC).format("YYYYMMDDTHHmmss")+"Z")+"&location="+encodeURIComponent(event.url)+"&text="+encodeURIComponent(event.name)}
                                                    target={"_blank"}
                                                    rel={"noreferrer"}
                                                    className={"btn btn-dark btn-sm"}
                                                >📅 + Save to Calendar</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="lead text-center text-muted">
                            Not found
                        </p>
                    </>
                )}
            </>
        </Layout>
    );
}
