import React from "react";
import {PageInfoContextProvider} from "./contexts/PageInfoContext";

export default function Providers(props: { children: JSX.Element }): JSX.Element {
    const { children } = props;

    return (
        <>
            <PageInfoContextProvider>
                <>
                    {children}
                </>
            </PageInfoContextProvider>
        </>
    );
}
