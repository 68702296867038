import React, {useContext, useEffect} from "react";
import Layout from "../Layout";
import {PageInfoContext} from "../contexts/PageInfoContext";
import {Link} from "react-router-dom";

export default function Guides(): JSX.Element {
    const { setTitle } = useContext(PageInfoContext);

    useEffect(() => {
        setTitle("Guides");
    }, []);

    return (
        <Layout>
            <>
                <header className="hero py-2 pb-4">
                    <h1 className={"m-0"}>
                        📙 Guides
                    </h1>
                </header>
                <div className="text-center">
                    <div className="row">
                        <div className="col-md-4 mb-5">
                            <h3 className={"mb-3"}>Canto</h3>
                            <p>
                                <Link to="/guides/contract-secured-revenue">
                                    1. What is Contract Secured Revenue (CSR)?
                                </Link>
                            </p>
                            <p>
                                <Link to="/guides/free-public-infrastructure">
                                    2. What is Free Public Infrastructure (FPI)?
                                </Link>
                            </p>
                        </div>
                        <div className="col-md-4 mb-5">
                            <h3 className={"mb-3"}>How to Bridge</h3>
                            <p>
                                <a href="https://docs.canto.io/user-guides/bridging-assets/to-canto#from-ethereum" target={"_blank"} rel="noreferrer">
                                    1. Ethereum to Canto
                                </a>
                            </p>
                            <p>
                                <a href="https://docs.canto.io/user-guides/bridging-assets/to-canto#from-cosmos-hub" target={"_blank"} rel="noreferrer">
                                    2. Cosmos Hub to Canto
                                </a>
                            </p>
                            <p>
                                <a href="https://docs.canto.io/user-guides/bridging-assets/to-canto#from-gravity-bridge" target={"_blank"} rel="noreferrer">
                                    3. Using Gravity Bridge
                                </a>
                            </p>
                            <p>
                                <a href="https://docs.synapseprotocol.com/how-to/bridge" target={"_blank"} rel="noreferrer">
                                    4. Using Synapse Bridge
                                </a>
                            </p>
                        </div>
                        <div className="col-md-4 mb-5">
                            <h3 className={"mb-3"}>Staking</h3>
                            <p>
                                <a href="https://docs.canto.io/user-guides/staking#how-to-stake" target={"_blank"} rel="noreferrer">
                                    1. How to Stake
                                </a>
                            </p>
                            <p>
                                <a href="https://docs.canto.io/user-guides/staking#claiming-rewards" target={"_blank"} rel="noreferrer">
                                    2. Claiming Rewards
                                </a>
                            </p>
                            <p>
                                <a href="https://docs.canto.io/user-guides/staking#re-delegating" target={"_blank"} rel="noreferrer">
                                    3. How to Re-delegate
                                </a>
                            </p>
                            <p>
                                <a href="https://docs.canto.io/user-guides/staking#how-to-unstake" target={"_blank"} rel="noreferrer">
                                    4. How to Unstake
                                </a>
                            </p>
                            <p>
                                <a href="https://docs.canto.io/user-guides/staking#risks-slashing" target={"_blank"} rel="noreferrer">
                                    5. Risks of Staking
                                </a>
                            </p>
                        </div>
                        <div className="col-md-4 mb-5">
                            <h3 className={"mb-3"}>Faucet</h3>
                            <p>
                                <a href="https://www.cpms.wtf/gas" target={"_blank"} rel="noreferrer">
                                    1. Getting 0.5 CANTO on mainnet
                                </a>
                            </p>
                        </div>
                        <div className="col-md-4 mb-5">
                            <h3 className={"mb-3"}>Validators</h3>
                            <p>
                                <a href="https://docs.canto.io/technical-reference/validators/faq" target={"_blank"} rel="noreferrer">
                                    1. Validator FAQ
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
}
