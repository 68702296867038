import React, {useContext, useEffect} from "react";
import Layout from "../Layout";
import {PageInfoContext} from "../contexts/PageInfoContext";
import neobaseLogo from "../assets/images/logos/neobase.svg";

export default function Analytics(): JSX.Element {
    const { setTitle } = useContext(PageInfoContext);

    useEffect(() => {
        setTitle("Analytics");
    }, []);

    return (
        <Layout>
            <>
                <header className="hero py-2 pb-4">
                    <h1 className={"m-0"}>
                        📊 Analytics
                    </h1>
                    <p className="ms-3 text-muted lead mt-3">
                        Powered by <img
                            src={neobaseLogo}
                            alt="Neobase"
                            style={{
                                height: "40px"
                            }}
                        />
                    </p>
                </header>
                <div className="text-center py-5">
                    <a
                        href={"https://analytics.canto.neobase.one/home"}
                        target={"_blank"}
                        rel="noreferrer"
                        className={"btn btn-dark btn-lg"}
                    >
                        View Dashboard{" "}
                        <small>
                            <sup>
                                <span className="fa-solid fa-arrow-up-right-from-square ms-2" />
                            </sup>
                        </small>
                    </a>
                </div>
            </>
        </Layout>
    );
}
