import React, {useState} from "react";

export interface IPageInfoContext {
    title: string | null
    setTitle(title: string | null): void
}

export const PageInfoContext = React.createContext<IPageInfoContext>({
    title: null,
    setTitle(title: string | null) {
        console.log(title);
    }
});

export function PageInfoContextProvider(props: { children: JSX.Element }): JSX.Element {
    const { children } = props;

    const [title, setTitle] = useState<string | null>(null);

    return (
        <PageInfoContext.Provider value={{
            title,
            setTitle,
        }}>{children}</PageInfoContext.Provider>
    );
}
