import React, {useContext, useEffect, useState} from "react";
import Moment from "react-moment";
import moment from "moment";
import Layout from "../Layout";
import {Link} from "react-router-dom";
import {PageInfoContext} from "../contexts/PageInfoContext";
import { IEvent } from "src/interfaces/Event";
import {IEventCategory} from "../interfaces/EventCategory";

export default function Calendar(): JSX.Element {
    const [events, setEvents] = useState<IEvent[] | null>(null);
    const [eventCategories, setEventCategories] = useState<{[eventCategoryId: string]: IEventCategory} | null>(null);
    const [selectedEventType, setSelectedEventType] = useState<string | null>(null);
    const [viewFinishedEvents, setViewFinishedEvents] = useState<boolean>(false);
    const [upcomingEvents, setUpcomingEvents] = useState<IEvent[]>([]);
    const [finishedEvents, setFinishedEvents] = useState<IEvent[]>([]);

    const { setTitle } = useContext(PageInfoContext);

    useEffect(() => {
        setTitle("Calendar");

        // fetch events
        fetch("https://raw.githubusercontent.com/CantoTools/data/main/src/events.json")
            .then(response => response.json())
            .then((data: { [eventKey: string]: IEvent }) => {
                console.log(data);
                setEvents(
                    Object.keys(data)
                        .map((eventKey: string): IEvent => ({
                            ...data[eventKey],
                            ...{
                                id: eventKey,
                                endUTC: new Date(data[eventKey].endUTC),
                                startUTC: new Date(data[eventKey].startUTC),
                            }
                        }))
                );
            });

        // fetch event categories
        fetch("https://raw.githubusercontent.com/CantoTools/data/main/src/event-categories.json")
            .then(response => response.json())
            .then((data: { [eventCategoryId: string]: IEventCategory }) => {
                setEventCategories(data);
            });
    }, []);

    useEffect(() => {
        if (null !== events) {
            console.log("now");
            console.log(events);
            console.log(
                Object.values(events)
                    .filter((event: IEvent) => event.endUTC > new Date())
            );
            console.log(
                Object.values(events)
                    .filter((event: IEvent) => event.endUTC < new Date())
            );

            setUpcomingEvents(
                Object.values(events)
                    .filter((event: IEvent) => event.endUTC > new Date())
                    .filter((event: IEvent) => null === selectedEventType ? true : event.eventCategoryIds.includes(selectedEventType))
            );

            setFinishedEvents(
                Object.values(events)
                    .filter((event: IEvent) => event.endUTC < new Date())
                    .filter((event: IEvent) => null === selectedEventType ? true : event.eventCategoryIds.includes(selectedEventType))
            );
        }
    }, [selectedEventType, events]);

    return (
        <Layout>
            <>
                <header className="hero py-2 pb-4">
                    <h1 className={"m-0"}>
                        📅 Calendar
                    </h1>
                    <p className="ms-3 text-muted lead mt-3">
                        See upcoming and previous events from the Canto ecosystem.
                    </p>
                </header>
                <ul className="list-inline text-center mb-5">
                    {null !== eventCategories && Object.keys(eventCategories)
                        .map(eventCategoryId => (
                            <li
                                key={eventCategoryId}
                                className={"list-inline-item"}
                            >
                                <button
                                    type={"button"}
                                    onClick={() => setSelectedEventType(selectedEventType === eventCategoryId ? null : eventCategoryId)}
                                    className={"btn btn-dark"}
                                >
                                    {selectedEventType === eventCategoryId && "✅"}{" "}
                                    {eventCategories[eventCategoryId].name}
                                </button>
                            </li>
                        ))}
                </ul>
                <div className="card">
                    <div className="card-header">Upcoming Events</div>
                    {upcomingEvents.length > 0 ? (
                        <table className="table m-0">
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Ends At</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {upcomingEvents
                                    .map((event: IEvent, index: number) => (
                                        <tr key={(event.name+index.toString(10))}>
                                            <td>
                                                <p className={"event-name m-0 text-white"}>
                                                    <Link
                                                        to={"/events/"+event.id}
                                                        className={"text-white text-decoration-none"}
                                                    >{event.name}</Link>
                                                </p>
                                                <a href={event.url} target={"_blank"} rel="noreferrer" className={"text-muted small"}>
                                            View
                                                    <small>
                                                        <sup>
                                                            <span className="fa-solid fa-arrow-up-right-from-square ms-2" />
                                                        </sup>
                                                    </small>
                                                </a>
                                            </td>
                                            <td>
                                                {event.eventCategoryIds.map(eventCategoryId => <span key={eventCategoryId} className="small me-2">{null !== eventCategories ? eventCategories[eventCategoryId]?.name || "Unknown" : eventCategoryId}</span>)}
                                            </td>
                                            <td>
                                                <p className={"m-0"}>
                                                    <Moment date={event.startUTC} format="MMM DD, YYYY" />
                                                </p>
                                                <p className={"small"}>
                                                    <Moment date={event.startUTC} format="HH:mm" />
                                                </p>
                                            </td>
                                            <td>
                                                <p className={"m-0"}>
                                                    <Moment date={event.endUTC} format="MMM DD, YYYY" />
                                                </p>
                                                <p className={"small"}>
                                                    <Moment date={event.endUTC} format="HH:mm" />
                                                </p>
                                            </td>
                                            <td className={"text-end"}>
                                                <a
                                                    href={"https://calendar.google.com/calendar/render?action=TEMPLATE&dates="+(moment(event.startUTC).format("YYYYMMDDTHHmmss")+"Z")+"%2F"+(moment(event.endUTC).format("YYYYMMDDTHHmmss")+"Z")+"&location="+encodeURIComponent(event.url)+"&text="+encodeURIComponent(event.name)}
                                                    target={"_blank"}
                                                    rel={"noreferrer"}
                                                    className={"btn btn-dark btn-sm"}
                                                >📅 Save</a>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className={"card-body text-center text-muted"}>
                        No upcoming events found.
                        </div>
                    )}
                </div>
                <div className="text-center mt-5">
                    <button
                        type={"button"}
                        onClick={() => setViewFinishedEvents(!viewFinishedEvents)}
                        className={"btn btn-dark btn-sm bg-transparent"+" "+(viewFinishedEvents ? "text-white" : "text-muted")}
                    >{viewFinishedEvents ? "Hide Previous Events" : "View Previous Events"}</button>
                </div>
                {viewFinishedEvents && (
                    <div className="card mt-5">
                        <div className="card-header">Previous Events</div>
                        {finishedEvents.length > 0 ? (
                            <table className="table m-0">
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Ends At</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {finishedEvents
                                        .map((event: IEvent, index: number) => (
                                            <tr key={(event.name+index.toString(10))}>
                                                <td>
                                                    <p className={"event-name m-0 text-white"}>
                                                        {event.name}
                                                    </p>
                                                    <a href={event.url} target={"_blank"} rel="noreferrer" className={"text-muted small"}>
                                                View
                                                        <small>
                                                            <sup>
                                                                <span className="fa-solid fa-arrow-up-right-from-square ms-2" />
                                                            </sup>
                                                        </small>
                                                    </a>
                                                </td>
                                                <td>
                                                    {event.eventCategoryIds.map(eventCategoryId => <span key={eventCategoryId} className="small me-2">{null !== eventCategories ? eventCategories[eventCategoryId]?.name || "Unknown" : eventCategoryId}</span>)}
                                                </td>
                                                <td>
                                                    <p className={"m-0"}>
                                                        <Moment date={event.startUTC} format="MMM DD, YYYY" />
                                                    </p>
                                                    <p className={"small"}>
                                                        <Moment date={event.startUTC} format="HH:mm" />
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className={"m-0"}>
                                                        <Moment date={event.endUTC} format="MMM DD, YYYY" />
                                                    </p>
                                                    <p className={"small"}>
                                                        <Moment date={event.endUTC} format="HH:mm" />
                                                    </p>
                                                </td>
                                                <td className={"text-end"}>
                                                    <a
                                                        href={"https://calendar.google.com/calendar/render?action=TEMPLATE&dates="+(moment(event.startUTC).format("YYYYMMDDTHHmmss")+"Z")+"%2F"+(moment(event.endUTC).format("YYYYMMDDTHHmmss")+"Z")+"&location="+encodeURIComponent(event.url)+"&text="+encodeURIComponent(event.name)}
                                                        target={"_blank"}
                                                        rel={"noreferrer"}
                                                        className={"btn btn-dark btn-sm"}
                                                    >📅 Save</a>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className={"card-body text-center text-muted"}>No previous events found.</div>
                        )}
                    </div>
                )}
            </>
        </Layout>
    );
}
