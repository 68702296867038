export const Tags: {
    [tagId: string]: {
        name: string
        description: string
    }
} = {
    coh_c1s1: {
        name: "COH C1S1",
        description: "Canto Online Hackathon | Chapter 1: Season 1"
    },
    coh_c1s2: {
        name: "COH C1S2",
        description: "Canto Online Hackathon | Chapter 1: Season 2"
    },
    coh_c1s3: {
        name: "COH C1S3",
        description: "Canto Online Hackathon | Chapter 1: Season 3"
    },
    coh_c1s4: {
        name: "COH C1S4",
        description: "Canto Online Hackathon | Chapter 1: Season 4"
    },
};
