import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DuneIframe from "../components/DuneIframe";
import Layout from "../Layout";
import {Tags} from "../data/Tags";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {PageInfoContext} from "../contexts/PageInfoContext";
import {IProject} from "../interfaces/Project";

export default function Project(): JSX.Element {
    const { projectId } = useParams();

    const { setTitle } = useContext(PageInfoContext);

    const [allProjects, setAllProjects] = useState<{ [projectKey: string]: IProject } | null>(null);
    const [project, setProject] = useState<IProject | null>(null);

    useEffect(() => {
        setProject(projectId && allProjects && allProjects[projectId] !== undefined ? allProjects[projectId] : null);
    }, [allProjects, projectId]);

    useEffect(() => {
        setTitle(project?.name ?? null);
    }, [project]);

    useEffect(() => {
        fetch("https://raw.githubusercontent.com/CantoTools/data/main/src/projects.json")
            .then(response => response.json())
            .then((data: { [projectKey: string]: IProject }) => {
                setAllProjects(data);
            });
    }, []);

    return (
        <Layout width={"wide"}>
            <>
                {project ? (
                    <>
                        <header className="hero pt-0 pb-4">
                            {project.logo ? (
                                <img
                                    src={"https://raw.githubusercontent.com/CantoTools/data/main/src/assets/logos/"+project.logo}
                                    alt={project.name}
                                    style={{
                                        height: "100px",
                                    }}
                                />
                            ) : (
                                <span style={{
                                    display: "inline-block",
                                    width: "100px",
                                    height: "100px",
                                    backgroundColor: "#333",
                                }}/>
                            )}
                            <h1 className={"mt-3 mb-0"}>
                                {project.name}
                            </h1>
                            <ul className="list-inline">
                                {project.tags?.map(tag => (
                                    <li
                                        key={tag}
                                        className="list-inline-item"
                                    >
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id={projectId+"-"+tag}>{Tags[tag]?.description}</Tooltip>}
                                        >
                                            <span className="badge bg-transparent text-muted small">
                                                {Tags[tag]?.name}
                                            </span>
                                        </OverlayTrigger>
                                    </li>
                                ))}
                            </ul>

                            <p className="ms-3 lead mt-3">
                                {project.description}
                            </p>
                            <div className="socials mb-3">
                                <ul className="list-inline m-0">
                                    {project?.socials?.twitter && (
                                        <li className={"list-inline-item"}>
                                            <a href={project.socials.twitter} target={"_blank"} className={"small text-decoration-none"} rel="noreferrer">
                                                <i className="fab fa-twitter" /> Twitter
                                            </a>
                                        </li>
                                    )}
                                    {project?.socials?.discord && (
                                        <li className={"list-inline-item"}>
                                            <a href={project.socials.discord} target={"_blank"} className={"small text-decoration-none"} rel="noreferrer">
                                                <i className="fab fa-discord" /> Discord
                                            </a>
                                        </li>
                                    )}
                                    {project?.website && (
                                        <li className={"list-inline-item"}>
                                            <a href={project.website} target={"_blank"} className={"text-decoration-none small"} rel="noreferrer">
                                                <i className="fas fa-globe-americas" />{" "}
                                                {project.website.replace("https://", "")}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </header>
                        {(project.duneQueryEmbeds && project.duneQueryEmbeds.length) ? (
                            <>
                                <h2 className={"text-center text-white py-3"}>
                                    Analytics
                                </h2>
                                <div className="row justify-content-center">
                                    {project.duneQueryEmbeds.map((src: string, index: number) => (
                                        <div
                                            key={src+index.toString(10)}
                                            className="col-md-6"
                                        >
                                            <DuneIframe src={src} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : <p className={"lead text-muted text-center d-none"}>No analytics found</p>}
                    </>
                ) : (
                    <>
                        <p className="lead text-center text-muted">
                            Not found
                        </p>
                    </>
                )}
            </>
        </Layout>
    );
}
