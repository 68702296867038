import React, {useContext, useEffect, useState} from "react";
import bg from "../assets/images/bg1.png";
import {Link} from "react-router-dom";
import {RouteList} from "../Routes";
import Layout from "../Layout";
import {PageInfoContext} from "../contexts/PageInfoContext";

export default function Home(): JSX.Element {
    const [price, setPrice] = useState<number|null>(null);

    const { setTitle } = useContext(PageInfoContext);

    useEffect(() => {
        setTitle("The Canto Dashboard");

        fetch("https://api.coingecko.com/api/v3/simple/price?ids=canto&vs_currencies=usd")
            .then((response) => response.json())
            .then((response) => setPrice(Number((Math.round(response.canto.usd * 100) / 100).toFixed(4))));
    }, []);

    return (
        <Layout>
            <>
                <header
                    className="hero"
                    style={{
                        backgroundImage: "url("+bg+")",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        padding: "10vh 0",
                        borderRadius: ".25rem"
                    }}
                >
                    <h1 className={"fw-bold"}>
                        The Canto Ecosystem
                    </h1>
                    <p className={"text-white lead"}>
                        The entire Canto ecosystem in one place.
                    </p>
                    <ul className="list-inline m-0">
                        <li className="list-inline-item">
                            <Link to={RouteList.ecosystem.path} className="btn btn-dark">
                                Ecosystem
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <a href={"https://evm.explorer.canto.io"} target={"_blank"} className="btn btn-dark" rel="noreferrer">
                                Explorer
                                <small>
                                    <sup>
                                        <span className="fa-solid fa-arrow-up-right-from-square ms-2" />
                                    </sup>
                                </small>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href={"https://docs.canto.io"} target={"_blank"} className="btn btn-dark" rel="noreferrer">
                                Docs
                                <small>
                                    <sup>
                                        <span className="fa-solid fa-arrow-up-right-from-square ms-2" />
                                    </sup>
                                </small>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href={"https://coingecko.com/en/coins/canto"} target={"_blank"} className="btn btn-dark" rel="noreferrer">
                                CoinGecko
                                <small>
                                    <sup>
                                        <span className="fa-solid fa-arrow-up-right-from-square ms-2" />
                                    </sup>
                                </small>
                            </a>
                        </li>
                    </ul>
                </header>
                <div className="bg-secondary text-black py-2 px-3 mb-3">
                    <p className="small">
                        <span className={"text-warning"}>Please DYOR.</span> Projects listed on canto.tools are listed for informational purposes only. We have not done audits of each project, so please proceed with caution.
                    </p>
                </div>
                <div className="py-5">
                    <div className="row text-white">
                        <div className="col-md-3 mb-md-0 mb-5 text-center text-md-start">
                            <div className="">
                                <h4>
                                    Current Price:
                                </h4>
                                <p className="display-6">
                                    {null === price ? "Loading..." : "$"+price}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-md-0 mb-5 text-center text-md-start">
                            <h4>
                                Ecosystem
                            </h4>
                            <p className={"text-muted small"}>
                                View all projects building on Canto.
                            </p>
                            <Link to={"/ecosystem"} className={"btn btn-primary btn-sm"}>
                                View Ecosystem
                            </Link>
                        </div>
                        <div className="col-md-3 mb-md-0 mb-5 text-center text-md-start">
                            <h4>
                                Calendar
                            </h4>
                            <p className={"text-muted small"}>
                                View the upcoming events in the Canto ecosystem.
                            </p>
                            <Link to={"/ecosystem"} className={"btn btn-primary btn-sm"}>
                                View Ecosystem
                            </Link>
                        </div>
                        <div className="col-md-3 mb-md-0 mb-5 text-center text-md-start">
                            <h4>
                                Guides
                            </h4>
                            <p className={"text-muted small"}>
                                Do you need any help? We have collected guides for the most frequent questions.
                            </p>
                            <Link to={"/ecosystem"} className={"btn btn-primary btn-sm"}>
                                View Ecosystem
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
}
