import React, {useContext, useEffect} from "react";
import Layout from "../../Layout";
import {PageInfoContext} from "../../contexts/PageInfoContext";
import {Link} from "react-router-dom";

export default function ContractSecuredRevenue(): JSX.Element {
    const { setTitle } = useContext(PageInfoContext);

    useEffect(() => {
        setTitle("Guides");
    }, []);

    return (
        <Layout>
            <>
                <p className={"small mb-5 text-center"}>
                    <Link to={"/guides"}>Guides</Link> / What is Contract Secured Revenue (CSR)?
                </p>
                <header className="hero py-2 pb-4">
                    <h1 className={"m-0"} style={{ fontSize: "2.5rem" }}>
                        What is Contract Secured Revenue (CSR)?
                    </h1>
                </header>
                <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8" style={{ letterSpacing: ".5px "}}>
                        <p>Contract Secured Revenue, or CSR, is an opt-in fee splitting model that allows developers to earn a percentage of any transaction fees paid to the network when users interact with their Canto-based decentralized applications.</p>
                        <p>CSR provides smart contract teams a new path to earn from their work without relying on extracting protocol fees from users or other rent-seeking behavior. It is designed to open a new path to economic sustainability for smart contract development in a way that upholds the network’s ethos.</p>
                        <p>To opt into CSR, developers must first deploy their smart contracts, after which they can register those contracts with the CSR Turnstile smart contract by sending a single transaction from the contracts’ deployer address.</p>
                        <h4 className={"h5 text-white mt-5"}>
                            Additional Resources on Contract Secured Revenue (CSR)
                        </h4>
                        <ol>
                            <li>
                                <a href="https://canto.mirror.xyz/QjMcVxG65ScvuK0uMQ9W7I0gyo77jrEUIKibxWz0ebI" target={"_blank"} rel="noreferrer">
                                    Introducing CIP-1 Contract Secured Revenue (CSR): Tokenized Fee Sharing for Canto Builders
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/Canto-Improvement-Proposals/CIPs/blob/main/CIP-001.md" target={"_blank"} rel="noreferrer">
                                    CIP-001
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </>
        </Layout>
    );
}
